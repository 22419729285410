import Home from "./pages/Home/Home";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Tours from "./pages/Tours/Tours";
import News from "./pages/News/News";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import { useEffect } from "react";
import { getCategories, getNews, getTours } from "./redux/apiCalls";
import { useDispatch } from "react-redux";
import Tour from "./pages/Tour/Tour";
import Jobs from "./pages/Jobs/Jobs";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    getCategories(dispatch);
    getTours(dispatch);
    getNews(dispatch);
  }, [dispatch]);
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/news">
          <News />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/jobs">
          <Jobs />
        </Route>
        <Route path="/tours">
          <Tours />
        </Route>
        <Route path="/tour/:id">
          <Tour />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
