import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
ReactDOM.render(
   <React.StrictMode> 
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      <ToastContainer /> 
    </PersistGate>
  </Provider>,
  </React.StrictMode>,
  document.getElementById("root")
);
