import React, { useEffect, useState } from "react";
import "../../assets/css/grid.css";
import "../../assets/css/base.css";
import "../../assets/css/style.css";
import "../../assets/css/reponsive.css";
import xoffer from "../../assets/images/xoffer_1.jpg.pagespeed.ic.LnLHOsFCJq.webp";
import xpost from "../../assets/images/xpost.png.pagespeed.ic.8S9NJqfU1S.webp";
import xcompass from "../../assets/images/xcompass.png.pagespeed.ic.BMlGyHi2Dm.webp";
import xbicycle from "../../assets/images/xbicycle.png.pagespeed.ic.-D-2GN2myH.webp";
import xsailboat from "../../assets/images/xsailboat.png.pagespeed.ic.bAIbYdxQvf.webp";
import { Rating } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTours } from "../../redux/apiCalls";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import DOMPurify from "dompurify";
export default function Offers() {
  const [value, setValue] = useState(5);
  const [loadingItems, setLoadingItems] = useState(false);
  const dispatch = useDispatch();
  const tours = useSelector((state) => state.tour.tours);
  useEffect(() => {
    getTours(dispatch);
    setLoadingItems(true);
  }, [dispatch]);

  return (
    <div className="offers">
      <div className="offers__containerPage">
        <div className="grid wide">
          <h2>TOP NHỮNG ĐIỂM DU LỊCH NỔI BẬT TRONG THÁNG</h2>
          <div className="row offers__container__items">
            {tours.map((tour) =>
              // Kiểm tra thuộc tính isFavorite của tour
              tour.isFavorite ? (
                loadingItems ? (
                  <div className="col l-6 m-12 c-12 s-12">
                    <Link
                      to={`/tour/${tour._id}`}
                      key={tour._id}
                      style={{
                        textDecoration: "none",
                        color: "#000000",
                      }}
                    >
                      <div className="offer__container__item">
                        <div className="row">
                          <div className="col l-6 m-6 c-6 s-12">
                            <div className="offer__item__img--container">
                              <div
                                className="offer__item__img"
                                style={{
                                  backgroundImage: `url(${tour.imgs[0]})`,
                                }}
                              ></div>
                              <span className="offer__container__item-date">
                                {tour.time}
                              </span>
                              <div className="offer__container__item-name">
                                <a>{tour.title}</a>
                              </div>
                            </div>
                          </div>
                          <div className="l-6 m-6 c-6 s-12">
                            <div className="offer__container__item--content">
                              <div className="offers__item__price">
                                {tour.price}
                                <span>VNĐ</span>
                              </div>
                              <div className="item_rating">
                                <Rating
                                  name="read-only"
                                  value={value}
                                  readOnly
                                  className="item_rating_i"
                                />
                              </div>
                              <div class="hiddent">
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      tour.descSummary
                                    ),
                                  }}
                                ></p>
                              </div>
                              <div className="offer__item--icon">
                                <img src={xpost} alt="" />
                                <img src={xcompass} alt="" />
                                <img src={xbicycle} alt="" />
                                <img src={xsailboat} alt="" />
                              </div>
                              <a>Chi Tiết</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <Box
                    sx={{ overflow: "hidden" }}
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={"210px"}
                      height={"161px"}
                    />
                    <Skeleton />
                    <Skeleton width="210px" animation="wave" />
                  </Box>
                )
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
