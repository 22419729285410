import {
  loginFailure,
  loginStart,
  loginSuccess,
  logoutSuccess,
  registerFailure,
  registerStart,
  registerSuccess,
} from "./userRedux";
import { publicRequest, userRequest } from "../requestMethods";
import { addOrderFailure, addOrderStart, addOrderSuccess } from "./orderRedux";
import {
  addMessageFailure,
  addMessageStart,
  addMessageSuccess,
} from "./messageRedux";
import { reset } from "./cartRedux";
import {
  getTourFailure,
  getTourStart,
  getTourSuccess,
} from "./tourRedux";
import {
  getJobFailure,
  getJobStart,
  getJobSuccess,
} from "./jobRedux";
import { getNewFailure, getNewStart, getNewSuccess } from "./newRedux";
import {
  getCategoryFailure,
  getCategoryStart,
  getCategorySuccess,
} from "./categoryRedux";
import { toast } from "react-toastify";
export const register = async (user, dispatch) => {
  dispatch(registerStart());
  try {
    const res = await publicRequest.post("/auth/register", user);
    dispatch(registerSuccess(res.data));
  } catch (err) {
    dispatch(registerFailure());
  }
};

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    dispatch(loginSuccess(res.data));
  } catch (err) {
    dispatch(loginFailure());
  }
};
export const logout = async (dispatch) => {
  dispatch(logoutSuccess());
};
export const getTours = async (cat, dispatch) => {
  dispatch(getTourStart());
  try {
    const res = await publicRequest.get(
      cat ? `/tours?category=${cat}` : "/tours"
    );
    dispatch(getTourSuccess(res.data));
  } catch (err) {
    dispatch(getTourFailure());
  }
};
// NEW
export const getNews = async (dispatch) => {
  dispatch(getNewStart());
  try {
    const res = await publicRequest.get("/news");
    dispatch(getNewSuccess(res.data));
  } catch (err) {
    dispatch(getNewFailure());
  }
};
// JOB
export const getJobs = async (dispatch) => {
  dispatch(getJobStart());
  try {
    const res = await publicRequest.get("/jobs");
    dispatch(getJobSuccess(res.data));
  } catch (err) {
    dispatch(getJobFailure());
  }
};
// Category
export const getCategories = async (dispatch) => {
  dispatch(getCategoryStart());
  try {
    const res = await publicRequest.get("/categories");
    dispatch(getCategorySuccess(res.data));
  } catch (err) {
    dispatch(getCategoryFailure());
  }
};
export const addOrder = async (order, dispatch) => {
  dispatch(addOrderStart());
  try {
    const res = await userRequest.post(`/orders`, order);
    dispatch(addOrderSuccess(res.data));
    toast.success("Đặt tour thành công, chúng tôi sẽ liên lạc với bạn sớm nhất!"); // Show success notification
  } catch (err) {
    dispatch(addOrderFailure());
    toast.error("Đặt tour thất bại, hãy thử lại."); // Show success notification
  }
};
export const addMessage = async (message, dispatch) => {
  dispatch(addMessageStart());
  try {
    const res = await userRequest.post(`/messages`, message);
    dispatch(addMessageSuccess(res.data));
    toast.success("Gửi thông tin thành công, chúng tôi sẽ liên lạc với bạn sớm nhất!"); // Show success notification
  } catch (err) {
    dispatch(addMessageFailure());
    toast.error("Gửi thông tin thất bại, hãy thử lại."); // Show success 
  }
};
