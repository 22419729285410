import React, { useState } from "react";
import "../../assets/css/contact.css";
import { useDispatch } from "react-redux";
import { addMessage } from "../../redux/apiCalls";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Contact() {
  const [inputs, setInputs] = useState({});
  const dispatch = useDispatch();
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleAddOrder = (e) => {
    // e.preventDefault();
    const message = {
      ...inputs,
    };
    addMessage(message, dispatch);
    
  };
  const handleSubmit = () => {
    if (inputs.fullname === undefined || inputs.phone === undefined) {
      toast.warning("Vui lòng nhập đầy đủ thông tin")
    } else {
      if (!isNaN(inputs.fullname)) {
        toast.warning("Họ và tên phải nhập chữ")
      } else {
        if (isNaN(inputs.phone)) {
          toast.warning("Số Điện Thoại Phải Nhập Số")
        } else {
          handleAddOrder();
        }
      }
    }
  };
  return (
    <div class="contact">
      <div class="contact__background"></div>
      <div class="grid wide">
        <div class="contact__container">
          <div class="row">
            <div class="col l-5"></div>
            <div class="col l-7">
              <div class="contact__container__form">
                <div class="contact__container__form--title">
                  <h2>LIÊN LẠC</h2>
                </div>
                <div class="contact__form">
                  <input
                    name="fullname"
                    placeholder="Nhập họ tên"
                    onChange={handleChange}
                    class="contact_form_name"
                  />
                  <input
                    type="text"
                    name="email"
                    placeholder="Nhập email"
                    onChange={handleChange}
                    class="contact_form_email"
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Nhập số điện thoại"
                    onChange={handleChange}
                    class="contact_form_subject"
                  />
                  <textarea
                    id=""
                    cols="30"
                    rows="10"
                    name="message"
                    placeholder="Nhập lời nhắn"
                    onChange={handleChange}
                  ></textarea>
                  <button class="form_submit_button" onClick={handleSubmit}>
                    Gửi Liên Lạc<span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
