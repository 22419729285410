import React from "react";

const JobDetail = ({ job }) => {
  return (
    <div className="job-detail">
      <h2>{job.title}</h2>
      <p>Mô tả công việc: {job.description}</p>
      <p>Yêu cầu công việc: {job.requirements}</p>
      <p>Lương: {job.salary}</p>
      <p>Địa điểm làm việc: {job.location}</p>
    </div>
  );
};

export default JobDetail;
