import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Search from "../../components/Search/Search";
import Tours from "../../components/Tours/Tours";
import Package from "../../components/Package/Package";
import Offers from "../../components/Offers/Offers";
import Testimonials from "../../components/Testimonials/Testimonials";
import Trending from "../../components/Trending/Trending";
import Contact from "../../components/Contact/Contact";
import Slider from "../../components/Slider/Slider";
export const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="container">
      <Navbar />
      <Slider />
      <Search />
      <Offers />
      <Tours />
      <Package />
      <Testimonials />
      {/* <Trending /> */}
      <Contact />
      <Footer />
    </div>
  );
};
export default Home;
