import { createSlice } from "@reduxjs/toolkit";

export const tourSlice = createSlice({
  name: "tour",
  initialState: {
    tours: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET ALL
    getTourStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getTourSuccess: (state, action) => {
      state.isFetching = false;
      state.tours = action.payload;
    },
    getTourFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getTourStart,
  getTourSuccess,
  getTourFailure,
} = tourSlice.actions;

export default tourSlice.reducer;
