import React, { useEffect, useRef } from "react";

const StatsBar = ({ dataX, dataY, color }) => {
  const statsRef = useRef(null);

  useEffect(() => {
    if (statsRef.current) {
      const stats = statsRef.current;
      const statsLeft = stats.querySelector(".stats__bar__perc--left");
      const statsRight = stats.querySelector(".stats__bar__perc--right");
      const valueElement = stats.querySelector(".stats__bar__value");

      const value = ((dataY - dataX) / dataX) * 100;
      const percX = (dataX / (dataX + dataY)) * 100;
      const percY = (dataY / (dataX + dataY)) * 100;

      statsLeft.style.width = percX + "%";
      statsRight.style.width = percY + "%";
      statsRight.style.background = color;
      if (value > 0) {
        valueElement.innerText = "+" + Math.round(value) + "%";
      } else {
        valueElement.innerText = Math.round(value) + "%";
      }
    }
  }, [dataX, dataY, color]);

  return (
    <div className="stats__bar" ref={statsRef}>
      <div className="stats__bar__perc">
        <div className="stats__bar__value"></div>
        <div className="stats__bar__perc--left"></div>
        <div className="stats__bar__perc--right"></div>
      </div>
    </div>
  );
};

export default StatsBar;
