import React, { useEffect, useRef } from "react";
import "../../assets/css/about.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import xintro from "../../assets/images/xintro.png.pagespeed.ic.wRAmcK6-ty.png";
import xstats_1 from "../../assets/images/xstats_1.png.pagespeed.ic.cR8aWTUlD5.webp";
import xmilestone_1 from "../../assets/images/about1.jpg";
import xmilestone_2 from "../../assets/images/about2.jpg";
import xmilestone_3 from "../../assets/images/xmilestone_3.png.pagespeed.ic.TqXoultJd7.webp";
import xmilestone_4 from "../../assets/images/xmilestone_4.png.pagespeed.ic.y4je2yi22m.webp";
import { Link } from "react-router-dom";
import StatsBar from "../../components/StatsBar/StatsBar";
export default function About() {
  return (
    <div>
      <Navbar />
      <div class="about">
        <div class="about__background"></div>
        <div class="about__title">
          <h1>Giới Thiệu</h1>
        </div>
      </div>
      <div className="intro">
        <div className="grid wide custom">
          <div className="row">
            <div className="col l-7 m-7 c-12 ">
              <div className="intro__image ">
                <img src={xintro} alt=" " />
              </div>
            </div>
            <div className="col l-5 m-5 c-12 ">
              <div className="intro__content ">
                <div className="intro__content--title ">
                  <h1>CHÚNG TÔI CÓ NHỮNG TOUR DU LỊCH TỐT NHẤT</h1>
                </div>
                <div className="intro__content--text ">
                  <p>
                    Chúng tôi tự hào giới thiệu đến bạn những tour du lịch tốt
                    nhất, đáp ứng mọi mong muốn và nhu cầu của khách hàng. Với
                    sự kết hợp tuyệt vời giữa chất lượng dịch vụ, trải nghiệm
                    độc đáo và giá trị vượt trội, chúng tôi cam kết mang đến cho
                    bạn những chuyến đi đáng nhớ và không thể quên.
                    <br />
                    Chúng tôi cung cấp các loại tour du lịch đa dạng, bao gồm
                    tour tự do, tour nhóm và tour cá nhân, để bạn có thể lựa
                    chọn phù hợp với mong muốn và lịch trình của mình. Bạn sẽ
                    được hưởng một dịch vụ chuyên nghiệp từ đội ngũ hướng dẫn
                    viên giàu kinh nghiệm, đảm bảo mọi yêu cầu và chi tiết được
                    chăm sóc đầy đủ.
                    <br />
                    Ngoài ra, chúng tôi cũng tổ chức các tour du lịch đặc biệt
                    như du lịch mạo hiểm, du lịch văn hóa, du lịch nghệ thuật và
                    du lịch thiên nhiên. Với những tour này, bạn có thể khám phá
                    những trải nghiệm độc đáo và khám phá những điểm đến ít
                    người biết đến.
                    <br />
                    Hãy để chúng tôi đồng hành cùng bạn trong những chuyến du
                    lịch tuyệt vời và tạo ra những kỷ niệm đáng nhớ trọn vẹn.
                    Đội ngũ chuyên gia của chúng tôi luôn sẵn lòng tư vấn và hỗ
                    trợ bạn để chọn lựa và đặt tour du lịch tốt nhất phù hợp với
                    bạn .
                  </p>
                </div>
              </div>
              <Link to="/service">
                <div className="tour__item--btn ">
                  <div className="tour__btn__bgc "></div>
                  <a href="# ">
                    Chi Tiết
                    <span></span>
                    <span></span>
                    <span></span>
                  </a>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div class="statistics">
        <div class="grid wide custom">
          <div class="statistics__title">
            <h1>Thống Kê Năm</h1>
          </div>
          <div class="statistics__text">
            <p>
              Chúng tôi là một nền tảng trực tuyến cung cấp thông tin về các
              điểm đến du lịch, các tour du lịch, đặt vé máy bay và khách sạn.
              Với tính năng tìm kiếm và đánh giá từ người dùng, trang web du
              lịch giúp người dùng tìm hiểu, lựa chọn và đặt các dịch vụ du lịch
              một cách tiện lợi và linh hoạt..
            </p>
          </div>

          <div class="statistics__years">
            <div class="row">
              <div class="col l-6 m-6 c-6">
                <span class="first__year">2016</span>
              </div>
              <div class="col l-6 m-6 c-6 text-right">
                <span class="last__year">2017</span>
              </div>
            </div>
          </div>
          <div class="statistics__content">
            <div class="row">
              <div class="col l-2 m-2 c-2">
                <div class="statistics__content__item">
                  <img src={xstats_1} alt="" />
                  <div class="content__item__stats">
                    <div class="stats__number">1642</div>
                    <div class="stats__type">Khách Hàng</div>
                  </div>
                </div>
              </div>
              <div class="col l-8 m-8 c-8 padding">
                <StatsBar dataX={1642} dataY={3527} color="#31124b" />
              </div>
              <div class="col l-2 m-2 c-2">
                <div class="statistics__content__item">
                  <div class="content__item__stats">
                    <div class="stats__number">3527</div>
                    <div class="stats__type">Khách Hàng</div>
                  </div>
                  <img src={xstats_1} alt="" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col l-2 m-2 c-2">
                <div class="statistics__content__item">
                  <img src={xstats_1} alt="" />
                  <div class="content__item__stats">
                    <div class="stats__number">768</div>
                    <div class="stats__type">Khách Hàng</div>
                  </div>
                </div>
              </div>
              <div class="col l-8 m-8 c-8 padding">
                <StatsBar dataX={768} dataY={145} color="#a95ce4" />
              </div>
              <div class="col l-2 m-2 c-2">
                <div class="statistics__content__item">
                  <div class="content__item__stats">
                    <div class="stats__number">145</div>
                    <div class="stats__type">Khách Hàng</div>
                  </div>
                  <img src={xstats_1} alt="" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col l-2 m-2 c-2">
                <div class="statistics__content__item">
                  <img src={xstats_1} alt="" />
                  <div class="content__item__stats">
                    <div class="stats__number">11546</div>
                    <div class="stats__type">Khách Hàng</div>
                  </div>
                </div>
              </div>
              <div class="col l-8 m-8 c-8 padding">
                <StatsBar dataX={11546} dataY={9321} color="#FF0000" />
              </div>
              <div class="col l-2 m-2 c-2">
                <div class="statistics__content__item">
                  <div class="content__item__stats">
                    <div class="stats__number">9321</div>
                    <div class="stats__type">Khách Hàng</div>
                  </div>
                  <img src={xstats_1} alt="" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col l-2 m-2 c-2">
                <div class="statistics__content__item">
                  <img src={xstats_1} alt="" />
                  <div class="content__item__stats">
                    <div class="stats__number">3729</div>
                    <div class="stats__type">Khách Hàng</div>
                  </div>
                </div>
              </div>
              <div class="col l-8 m-8 c-8 padding">
                <StatsBar dataX={3729} dataY={17429} color="#fa9e1b" />
              </div>
              <div class="col l-2 m-2 c-2">
                <div class="statistics__content__item">
                  <div class="content__item__stats">
                    <div class="stats__number">17429</div>
                    <div class="stats__type">Khách Hàng</div>
                  </div>
                  <img src={xstats_1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="sale">
        <div class="grid wide custom">
          <div class="sale__container">
            <div class="sale__container__content">
              <div class="sale__container__content__body">
                <h1 class="sale__container__body--title">VUNGTAU</h1>
                <div class="sale__container__body--price">
                  Chỉ Từ <span>1.500.000 VND</span>
                </div>
                <Link to="/service">
                  <div class="tour__item--btn ">
                    <div class="tour__btn__bgc "></div>
                    <a href="# ">
                      Chi Tiết
                      <span></span>
                      <span></span>
                      <span></span>
                    </a>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="milestones">
        <div class="grid wide custom">
          <div class="milestones__container">
            <div class="row">
              <div class="milestones__container__item">
                <img src={xmilestone_1} alt=""  style={{width:"100px",marginBottom:"-20px"}}/>
                <div class="milestones__container__item--stat">
                  <span>255</span>
                </div>
                <div class="milestones__container__item--text">Khách Hàng</div>
              </div>
              <div class="milestones__container__item margin-top-mb">
                <img src={xmilestone_2} alt=" " style={{width:"100px",marginBottom:"-20px"}} />
                <div class="milestones__container__item--stat ">
                  <span>1176</span>
                </div>
                <div class="milestones__container__item--text ">Dự Án</div>
              </div>
              <div class="milestones__container__item margin-top">
                <img src={xmilestone_3} alt=" " />
                <div class="milestones__container__item--stat ">
                  <span>39</span>
                </div>
                <div class="milestones__container__item--text ">Đất Nước</div>
              </div>
              <div class="milestones__container__item margin-top">
                <img src={xmilestone_4} alt=" " />
                <div class="milestones__container__item--stat ">
                  <span>10027</span>
                </div>
                <div class="milestones__container__item--text ">Du Khách</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
