import React, { useState } from "react";
import "../../assets/css/grid.css";
import "../../assets/css/base.css";
import "../../assets/css/style.css";
import "../../assets/css/reponsive.css";
import packageBackground from "../../assets/images/xcta.jpg.pagespeed.ic.m69gPEowgD.webp";
import { Rating } from "@mui/material";
import { Link } from "react-router-dom";
const Package = () => {
  const [value, setValue] = useState(5);
  return (
    <div className="package">
      <div
        className="package__background"
        style={{
          backgroundImage: { packageBackground },
        }}
      ></div>
      <div className="package__container">
        <div className="grid wide">
          <div className="package__container___body">
            <div className="package__container___body--content">
              <div className="body--content__cta">
                <h2>MANG TỚI TRẢI NGHIỆM TUYỆT VỜI</h2>
                <div className="item_rating">
                  <br />
                  <Rating
                    name="read-only"
                    value={value}
                    readOnly
                    className="item_rating_i"
                  />
                </div>
                <p>
                  Bạn muốn đi du lịch, nhưng với rất nhiều nơi cần ghé thăm, bạn
                  bắt đầu từ đâu ? Hãy nhìn vào Top những điểm du lịch nổi bật
                  của chúng tôi để ghé thăm nhé !
                </p>
              </div>
              <div className="item--btn">
                <div className="btn__bgc"></div>
                <Link
                to='/service'
                  style={{
                    textDecoration: "none",
                    color: "#000000",
                  }}
                >
                  <a>
                    Đặt Ngay
                    <span></span>
                    <span></span>
                    <span></span>
                  </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Package;
