import { createSlice } from "@reduxjs/toolkit";

export const jobSlice = createSlice({
  name: "job",
  initialState: {
    jobs: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET ALL
    getJobStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getJobSuccess: (state, action) => {
      state.isFetching = false;
      state.jobs = action.payload;
    },
    getJobFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getJobStart,
  getJobSuccess,
  getJobFailure,
} = jobSlice.actions;

export default jobSlice.reducer;
