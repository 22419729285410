import React, { useEffect } from "react";
import "../../assets/css/news.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import blog_1 from "../../assets/images/blog_1.jpg";
import xoffer_4 from "../../assets/images/xoffer_4.jpg.pagespeed.ic.sP-uVX8-UR.webp";
import xoffer_1 from "../../assets/images/xoffer_1.jpg.pagespeed.ic.LnLHOsFCJq.webp";
import gallery_3 from "../../assets/images/gallery_3.jpg";
import gallery_4 from "../../assets/images/gallery_4.jpg";
import gallery_5 from "../../assets/images/gallery_5.jpg";
import gallery_6 from "../../assets/images/gallery_6.jpg";
import SearchIcon from '@mui/icons-material/Search';
export default function News() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Navbar />
      <div className="news">
        <div className="news__background"></div>
        <div className="news__title">
          <h1>Tin Tức</h1>
        </div>
      </div>
      <div className="blog">
        <div className="grid wide custom">
          <div className="blog__container">
            <div className="grid wide custom">
              <div className="row">
                <div className="l-8 m-8 c-12">
                  <div className="blog__container__post">
                    <div className="blog__container__post--time">
                      <span>01</span>
                      <span>July, 2017</span>
                    </div>
                    <img src={blog_1} alt="" />
                  </div>
                </div>
                <div className="l-4 m-4 c-12">
                  <div className="blog__container__sidebar">
                    <input type="text" />
                   <SearchIcon className="i"/>
                    <div className="container__sidebar--year">
                      <span>Archives</span>
                      <ul>
                        <li>March 2017</li>
                        <li>April 2017</li>
                        <li>May 2017</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row margin-top">
                <div className="l-8 m-8 c-12">
                  <div className="blog__container__post">
                    <ul>
                      <li>
                        <a>by Lore Papp |</a>
                      </li>
                      <li>
                        <a>Uncategorized |</a>
                      </li>
                      <li>
                        <a>3 Comments</a>
                      </li>
                    </ul>
                    <a className="blog__container__post--title">
                      Try these new dream destinations
                    </a>
                    <p>
                      Aenean in lacus ligula. Phasellus euismod gravida eros.
                      Aenean nec ipsum aliquet, pharetra magna id, interdum
                      sapien. Etiam id lorem eu nisl pellentesque semper. Nullam
                      tincidunt metus placerat, suscipit leo ut, tempus nulla.
                      Fusce at eleifend tellus. Ut eleifend dui nunc, non
                      fermentum qua.
                    </p>
                    <a className="blog__container__post--btn">Read More</a>
                  </div>
                </div>
                <div className="l-4 m-4 c-12 top">
                  <div className="blog__container__sidebar">
                    <div className="container__sidebar--list">
                      <span>Categories</span>
                      <ul>
                        <li>
                          <a>Travel</a>
                        </li>
                        <li>
                          <a>Exotic Destinations</a>
                        </li>
                        <li>
                          <a>City Breaks</a>
                        </li>
                        <li>
                          <a>Travel Tips</a>
                        </li>
                        <li>
                          <a>Lifestyle &amp; Travel</a>
                        </li>
                        <li>
                          <a>City Breaks</a>
                        </li>
                        <li>
                          <a>Uncategorized</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row margin-top">
                <div className="l-8 m-8 c-12">
                  <div className="blog__container__post">
                    <ul>
                      <li>
                        <a>by Lore Papp |</a>
                      </li>
                      <li>
                        <a>Uncategorized |</a>
                      </li>
                      <li>
                        <a>3 Comments</a>
                      </li>
                    </ul>
                    <a className="blog__container__post--title">
                      Try these new dream destinations
                    </a>
                    <p>
                      Aenean in lacus ligula. Phasellus euismod gravida eros.
                      Aenean nec ipsum aliquet, pharetra magna id, interdum
                      sapien. Etiam id lorem eu nisl pellentesque semper. Nullam
                      tincidunt metus placerat, suscipit leo ut, tempus nulla.
                      Fusce at eleifend tellus. Ut eleifend dui nunc, non
                      fermentum qua.
                    </p>
                    <a className="blog__container__post--btn">Read More</a>
                  </div>
                </div>
                <div className="l-4 m-4 c-12 top">
                  <div className="blog__container__sidebar">
                    <div className="container__sidebar--list">
                      <span>Instagram</span>
                      <div className="container__sidebar--list-img">
                        <img src={xoffer_4} alt="" />
                        <img src={xoffer_1} alt="" />
                        <img src={gallery_3} alt="" />
                        <img src={gallery_4.jpg} alt="" />
                        <img src={gallery_5.jpg} alt="" />
                        <img src={gallery_6.jpg} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row margin-top">
                <div className="l-8 m-8 c-12">
                  <div className="blog__container__post">
                    <div className="blog__container__post--time">
                      <span>01</span>
                      <span>July, 2017</span>
                    </div>
                    <img src={blog_1} alt="" />
                  </div>
                </div>
                <div className="l-4 m-4 c-12 top">
                  <div className="blog__container__sidebar">
                    <div className="container__sidebar--list">
                      <span>Categories</span>
                      <ul>
                        <li>
                          <a>Travel</a>
                        </li>
                        <li>
                          <a>Exotic Destinations</a>
                        </li>
                        <li>
                          <a>City Breaks</a>
                        </li>
                        <li>
                          <a>Travel Tips</a>
                        </li>
                        <li>
                          <a>Lifestyle &amp; Travel</a>
                        </li>
                        <li>
                          <a>City Breaks</a>
                        </li>
                        <li>
                          <a>Uncategorized</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row margin-top">
                <div className="l-8 m-8 c-12">
                  <div className="blog__container__post">
                    <ul>
                      <li>
                        <a>by Lore Papp |</a>
                      </li>
                      <li>
                        <a>Uncategorized |</a>
                      </li>
                      <li>
                        <a>3 Comments</a>
                      </li>
                    </ul>
                    <a className="blog__container__post--title">
                      Try these new dream destinations
                    </a>
                    <p>
                      Aenean in lacus ligula. Phasellus euismod gravida eros.
                      Aenean nec ipsum aliquet, pharetra magna id, interdum
                      sapien. Etiam id lorem eu nisl pellentesque semper. Nullam
                      tincidunt metus placerat, suscipit leo ut, tempus nulla.
                      Fusce at eleifend tellus. Ut eleifend dui nunc, non
                      fermentum qua.
                    </p>
                    <a className="blog__container__post--btn">Read More</a>
                  </div>
                </div>
                <div className="l-4 m-4 c-12"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
