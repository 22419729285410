import React, { useState } from "react";
import "../../assets/css/grid.css";
import "../../assets/css/base.css";
import "../../assets/css/style.css";
import "../../assets/css/reponsive.css";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import trip from "../../assets/images/trip.webp";
const Search = () => {
  const location = useLocation();
  const cat = location.pathname.split("/")[2];
  const [loading, setLoading] = useState(true);
  const categories = useSelector((state) => state.category.categories);
  return (
    <div className="search">
      <div className="grid wide">
        <div className="search__container__tabs grid wide">
          <div className="row">
            <Link
              to="/tours"
              style={{ textDecoration: "none", color: "#000000" }}
              className={`search__container__tab d-flex ${
                cat == null ? "active" : ""
              }`}
            >
              <img src={trip} alt="" />
              <span>Tất cả chuyến đi</span>
            </Link>
            {loading ? (
              categories.map((category) => (
                <Link
                  to={`/tours/${category.cat}`}
                  key={category._id}
                  style={{ textDecoration: "none", color: "#000000" }}
                  className={`search__container__tab d-flex ${
                    cat == category.cat ? "active" : ""
                  }`}
                >
                  <img src={category.img} alt="" />
                  <span>{category.title}</span>
                </Link>
              ))
            ) : (
              <Skeleton
                variant="rounded"
                animation="wave"
                width={"305px"}
                style={{ marginLeft: "-4px" }}
                height={"300px"}
              />
            )}
          </div>
        </div>
        {/* <div className="search__container">
          <div className="row">
            <div className="search__container--item l-4 m-6 c-12">
              <div>
                <span>Điểm Đến</span>
              </div>
              <input type="text" />
            </div>
            <div className="search__container--item l-2 m-6 c-12">
              <div>
                <span>Giờ Đến</span>
              </div>
              <input type="text" placeholder="DD-MM-YYYY" />
            </div>
            <div className="search__container--item l-2 m-6 c-12">
              <div>
                <span>Giờ đi</span>
              </div>
              <input type="text" />
            </div>
            <div className="search__container--item l-1 m-3 c-6">
              <div>
                <span>Người Lớn</span>
              </div>
              <select name="" id="">
                <option>01</option>
                <option>02</option>
                <option>03</option>
                <option>04</option>
                <option>05</option>
                <option>06</option>
                <option>07</option>
                <option>08</option>
                <option>09</option>
                <option>10</option>
              </select>
            </div>
            <div className="search__container--item l-1 m-3 c-6">
              <div>
                <span>Trẻ Em</span>
              </div>
              <select name="" id="">
                <option>01</option>
                <option>02</option>
                <option>03</option>
                <option>04</option>
                <option>05</option>
                <option>06</option>
                <option>07</option>
                <option>08</option>
                <option>09</option>
                <option>10</option>
              </select>
            </div>
            <div className="search__container--btn l-2 m-4 c-4">
              <Link to="/service">
                <a>Tìm Kiếm</a>
                <span></span>
                <span></span>
                <span></span>
              </Link>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Search;
