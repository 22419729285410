import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./tour.css";
import xintro from "../../assets/images/xintro.png.pagespeed.ic.wRAmcK6-ty.png";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { addOrder, getTours } from "../../redux/apiCalls";
import { Backdrop, CircularProgress, Rating } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styled from "styled-components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ProductImgChild = styled.img`
  width: 150px;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  margin: 10px 5px;
  object-fit: cover;
  border: 3px solid #ccc;
  margin-bottom: 50px;
`;
export default function Tour() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [value, setValue] = useState(5);
  const location = useLocation();
  const dispatch = useDispatch();
  const tourId = location.pathname.split("/")[2];

  const tour = useSelector((state) =>
    state.tour.tours.find((tour) => tour._id === tourId)
  );

  const sanitizedHTMLdescSummary = DOMPurify.sanitize(tour.descSummary);

  const sanitizedHTMLdescDetails = DOMPurify.sanitize(tour.descDetails);

  useEffect(() => {
    getTours(dispatch);
  }, [dispatch]);
  const [inputs, setInputs] = useState({});

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleAddOrder = (e) => {
    // e.preventDefault();
    const order = {
      ...inputs,
    };
    addOrder(order, dispatch);
  };

  const minDate = new Date().toISOString().split("T")[0];

  const handleInputFocus = (event) => {
    event.target.type = "date";
    event.target.focus();
  };

  const handleSubmit = () => {
    if (inputs.fullname === undefined || inputs.phone === undefined) {
      toast.warning("Vui lòng nhập đầy đủ thông tin")
    } else {
      if (!isNaN(inputs.fullname)) {
        toast.warning("Họ và tên phải nhập chữ")
      } else {
        if (isNaN(inputs.phone)) {
          toast.warning("Số Điện Thoại Phải Nhập Số")
        } else {
          handleAddOrder();
        }
      }
    }
  };
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedImg, setSelectedImg] = useState(tour.imgs[currentIndex]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(handleNextImg, 3000);
    setTimeoutId(timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentIndex]);

  const handleImgClick = (imgUrl) => {
    setSelectedImg(imgUrl);
    clearTimeout(timeoutId);
  };

  const handleNextImg = () => {
    if (isTransitioning) return;
    const nextIndex = (currentIndex + 1) % tour.imgs.length;
    setCurrentIndex(nextIndex);
    setSelectedImg(tour.imgs[nextIndex]);
    clearTimeout(timeoutId);
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
    }, 500);
  };

  const handlePrevImg = () => {
    if (isTransitioning) return;
    const prevIndex = (currentIndex - 1 + tour.imgs.length) % tour.imgs.length;
    setCurrentIndex(prevIndex);
    setSelectedImg(tour.imgs[prevIndex]);
    clearTimeout(timeoutId);
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
    }, 500);
  };

  return (
    <div>
      <Navbar />
      <div className="tour">
        <div className="grid wide custom">
          <div className="row">
            <div className="col l-7 m-7 c-12 ">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div>
                  <ArrowBackIosNewIcon
                    style={{
                      fontSize: 40,
                      color: "#000000",
                      borderRadius: "100%",
                      padding: "5px",
                      marginLeft: "-50px",
                    }}
                    class="arrow"
                    onClick={handlePrevImg}
                  />
                </div>
                <div className="tour__image ">
                  <img src={selectedImg} alt={tour.title} />
                </div>
                <div>
                  <ArrowForwardIosIcon
                    style={{
                      fontSize: 40,
                      color: "#000000",
                      borderRadius: "100%",
                      padding: "5px",
                      marginRight: "-12px",
                      marginLeft: "-39px",
                    }}
                    class="arrow"
                    onClick={handleNextImg}
                  />
                </div>
              </div>
              <div
                className="col l-12 imgChild"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  marginLeft: "-20px",
                }}
              >
                {tour.imgs.map((imgUrl, index) => (
                  <ProductImgChild
                    key={index}
                    src={imgUrl}
                    alt={tour.title}
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      cursor: "pointer",
                      transition: "opacity 0.3s ease",
                      opacity: selectedImg === imgUrl ? 1 : 0.5,
                    }}
                    onClick={() => handleImgClick(imgUrl)}
                  />
                ))}
              </div>
              <div className="tour__content--title ">
                <h1>{tour.title}</h1>
              </div>
              
              <div className="tour__content--text ">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizedHTMLdescDetails,
                  }}
                  className="sanitized-html-container"
                  style={{maxWidth: "100% !important",padding:"10px" }}
                ></div>
              </div>
            </div>
            <div className="col l-5 m-5 c-12 ">
              <div className="tour__content ">
                <div className="tour__content--title ">
                  <h1>{tour.title}</h1>
                  <h3>{tour.time}</h3>
                </div>
                <div className="tour_price">
                  {tour.price} VNĐ
                  <span> / người</span>
                </div>
                <div className="item_rating">
                  <Rating
                    name="read-only"
                    value={value}
                    readOnly
                    className="item_rating_i"
                  />
                </div>
                <div className="tour__content--text ">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: sanitizedHTMLdescSummary,
                    }}
                    className="sanitized-html-container"
                  ></p>
                </div>
                <div className="tour__infomation">
                  <div className="tour__infomation--title">Điền thông tin</div>
                  <div className="tour__infomation--form">
                    <div className="tour__infomation--input">
                      <input
                        type="text"
                        name="fullname"
                        placeholder="Nhập họ tên"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="tour__infomation--input">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Nhập số điện thoại"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="tour__infomation--input">
                      <input
                        type="text"
                        name="travelDay"
                        placeholder="Nhập số ngày"
                        onFocus={handleInputFocus}
                        min={minDate}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="tour__infomation--input">
                      <input
                        type="text"
                        name="person"
                        placeholder="Nhập số người"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="tour__infomation--btn" onClick={handleSubmit}>
                    <div className="tour__btn__bgc"></div>
                    <a>
                      Đặt ngay
                      <span></span>
                      <span></span>
                      <span></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
