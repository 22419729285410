import React, { useEffect, useState } from "react";
import Rating from "@mui/material/Rating";
import "../../assets/css/grid.css";
import "../../assets/css/base.css";
import "../../assets/css/style.css";
import "../../assets/css/reponsive.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTours } from "../../redux/apiCalls";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
const Tours = () => {
  const [value, setValue] = useState(5);
  const [loadingItems, setLoadingItems] = useState(false);
  const dispatch = useDispatch();
  const tours = useSelector((state) => state.tour.tours);
  const [currentPage, setCurrentPage] = useState(1);
  const servicesPerPage = 6;

  useEffect(() => {
    getTours(dispatch);
    setLoadingItems(true);
  }, [dispatch, currentPage]);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo({
      top: 1700,
      left: 0,
      behavior: "smooth",
    });
  };
  const indexOfLastService = currentPage * servicesPerPage;
  const indexOfFirstService = indexOfLastService - servicesPerPage;
  const currentServices = useSelector((state) =>
    state.tour.tours.slice(indexOfFirstService, indexOfLastService)
  );
  const totalPages = Math.ceil(tours.length / servicesPerPage);
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }
  return (
    <div className="tour">
      <div className="grid wide">
        <div className="tour__container">
          <div className="tour__container__content">
            <h2>Chúng tôi có các tour du lịch tốt nhất</h2>
            <p>
              Tìm kiếm cảm hứng?
              <br />
              Hãy để chúng tôi giúp bạn tìm nơi hoàn hảo. <br />
              Điểm đến lý tưởng cho kỳ nghỉ cuối tuần hoặc tuần trăng mật
            </p>
          </div>
          <div className="tour__container__items">
            <div className="row">
              {currentServices.map((tour) =>
                loadingItems ? (
                  <div className="col l-4 m-12 c-12">
                    <div className="tour__container__item">
                      <div className="item__overlay"></div>
                      <Link
                        to={`/tour/${tour._id}`}
                        key={tour._id}
                        style={{
                          textDecoration: "none",
                          color: "#000000",
                        }}
                      >
                        <div
                          className="item__background"
                          style={{ backgroundImage: `url(${tour.imgs[0]})` }}
                        ></div>
                        <div className="item__content">
                          <span className="item_date">{tour.time}</span>
                          <div className="item_center">
                            <h2>TOUR</h2>
                            <h1>{tour.title}</h1>
                            <br />

                            <div className="item_rating">
                              <Rating
                                name="read-only"
                                value={value}
                                readOnly
                                className="item_rating_i"
                              />
                            </div>
                            <br />
                            <div className="tour__item--btn">
                              <div className="tour__btn__bgc"></div>
                              <a>
                                Chi Tiết
                                <span></span>
                                <span></span>
                                <span></span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <Box
                    sx={{ overflow: "hidden" }}
                    style={{ marginLeft: "10px", marginRight: "10px" }}
                  >
                    <Skeleton
                      variant="rounded"
                      animation="wave"
                      width={"210px"}
                      height={"161px"}
                    />
                    <Skeleton />
                    <Skeleton width="210px" animation="wave" />
                  </Box>
                )
              )}
            </div>
          </div>
        </div>
        {totalPages > 1 && (
          <div className="pagination">
            {pageNumbers.map((pageNumber) => (
              <span
                key={pageNumber}
                className={`page-number ${
                  pageNumber === currentPage ? "active" : ""
                }`}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Tours;
