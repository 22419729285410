import React, { useEffect, useState } from "react";
import "../../assets/css/jobs.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import JobDetail from "../../components/JobDetail/JobDetail";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { getJobs } from "../../redux/apiCalls";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Jobs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [selectedJob, setSelectedJob] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    getJobs(dispatch);
  }, [dispatch]);
  
  const jobs = useSelector((state) => state.job.jobs);
  const openZaloAndSearch = () => {
    var phoneNumber = "0902666576";
    var zaloURL = "https://zalo.me/";
    var fullURL = zaloURL + phoneNumber;

    window.open(fullURL, "_blank");
  };
  const handleJobClick = (job) => {
    setSelectedJob(job);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    setOpen(false);
    openZaloAndSearch();
  };

  return (
    <div>
      <Navbar />
      <div className="jobs">
        <div className="jobs__background"></div>
        <div className="jobs__title"><h1>Tuyển dụng</h1></div>
      </div>
      <div className="App">
        <header className="App-header">
          <h1>Chúng tôi đang cần tuyển dụng các vị trí:</h1>
        </header>
        <main className="App-content">
          <div className="job-list">
            {jobs.map((job) => (
              <div
                className="job"
                key={job.id}
                onClick={() => handleJobClick(job)}
              >
                <h2>
                  <span style={{color:"#000000"}}>Vị trí: </span>
                  {job.title}</h2>
                <p>
                <span style={{color:"#000000"}}>Mô tả công việc:  </span>
                  {job.description}</p>
                <button onClick={handleClickOpen}>Ứng tuyển</button>
              </div>
            ))}
          </div>
          <div>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {selectedJob && <JobDetail job={selectedJob} />}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Thoát</Button>
                <Button onClick={handleConfirm}>Ứng tuyển</Button>
              </DialogActions>
            </Dialog>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}
